<template>
    <div id="mint">
        <div class="upLoadTitle">Single Upload</div>
        <div class="uploadNotes">Picture<span class="required">*</span></div>
        <div class="uploadNotesInfo">Please name NFTs using serial numbers. Ex. #1, #2, #100…etc.</div>
        <div class="uploadNotesInfo">File types supported: JPG, PNG, SVG<span>SVG Max size: 100 MB<span class="required">*</span></span></div>
        <div class="uploadImg">
            <el-upload :class="{hide:hideUpload}" ref="my-upload" :action="baseUrl" list-type="picture-card" :file-list="fileList" :on-change="handleChange" :before-upload="logoBeforeUpload" :on-remove="handleRemove" :on-success="logoHandleSuccess">
                <i class="el-icon-picture"></i>
            </el-upload>
        </div>
        <div class="form_view">
            <div class="form_view_title">Name<span>*</span></div>
            <div class="form_view_input"><input type="text" v-model="minNewHistoryQuery.seriesname" placeholder="NFT Name"></div>
        </div>
        <div class="form_view">
            <div class="form_view_title">Desc<span>*</span></div>
            <div class="uploadNotesInfo">NFT Description</div>
            <div class="form_view_input"><textarea v-model="minNewHistoryQuery.description" placeholder="Describe your NFT" /></div>
    </div>
    <div class="mintCreate_button">
      <button :disabled="uploadImg===''||minNewHistoryQuery.description===''||minNewHistoryQuery.seriesname===''" :class="{'mintCreate_button_true': uploadImg!==''&&minNewHistoryQuery.description!==''&&minNewHistoryQuery.seriesname!==''}" @click="mintHandle">
        <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
        <span v-else>Submit</span>
      </button>
    </div>
  </div>
</template>

<script>
const wallet = require('../../../web3/common/wallet.js');
export default {
  data(){
    return{
      baseUrl: "https://api.blockhistory.io/history/uploadseriesnftpic",
      // baseUrl: "http://18.222.137.245:9081/history/uploadseriesnftpic",
      baseUrlData:'',
      file: {},
      fileList: [],
      uploadImg: '',
      hideUpload: false,
      operationLoading: false,
      seriesid:'',
      querytokenaddress:'',
      minNewHistoryQuery:{
        seriesname:"",
        serieslogo:"",
        description:"",
        admin:'',
        network:'',
      }
    }
  },
   computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
      
    },
  mounted(){
    this.baseUrlData = localStorage.getItem('baseUrl')
  },
  created(){
    this.seriesid = this.$route.query.id
    this.querytokenaddress = this.$route.query.tokenAddress
  },
  methods:{
    logoHandleSuccess(response){
      this.uploadImg = response.result.awsUrl;
      this.minNewHistoryQuery.serieslogo = response.result.hash;
    },
    handleChange(file,fileList){
      fileList.length >= 1 ? this.hideUpload = true : this.hideUpload = false;
    },
    handleRemove(file, fileList){
      fileList.length >= 1 ? this.hideUpload = true : this.hideUpload = false;
      this.uploadImg = '';
      this.minNewHistoryQuery.serieslogo = '';
    },
    logoBeforeUpload(file){
      this.file = file;
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error('The size of the uploaded picture cannot exceed 100MB!');
      }
      return isLt2M;
    },
    mintHandle(){
      if(this.minNewHistoryQuery.description !== '' && this.uploadImg !== '' && this.minNewHistoryQuery.seriesname !== ''){
        this.operationLoading = true;
        this.uploadtextRequire()
      }
    },
    uploadtextRequire(){
      if (this.mapState.chainId) {
        wallet.getWalletInfo().then((info) => {
          this.minNewHistoryQuery.admin = info.address.toLowerCase();
          this.minNewHistoryQuery.network = info.network;
          this.mintServer();
        })
      }
    },
    mintServer(){
      let _this = this;
      let params = {
          admin:_this.minNewHistoryQuery.admin,
          network:_this.minNewHistoryQuery.network,
          seriesid:this.seriesid,
          image:_this.minNewHistoryQuery.serieslogo,
          description:_this.minNewHistoryQuery.description,
          name:_this.minNewHistoryQuery.seriesname,
          tokenaddress:this.querytokenaddress
      };
        this.$axios.post(this.baseUrlData + this.$api.post_createmetainfo,params).then(()=>{
          setTimeout(()=>{
            this.$router.go(-1)
          },1500)
          this.$message({
            message: 'Operation succeeded',
            type: 'success'
          });
          _this.clearForm();
        })
    },
    clearForm(){
      this.operationLoading = false;
      this.minNewHistoryQuery = {
        admin:"",
        seriesname:"",
        serieslogo:"",
        description:"",
      };
      this.uploadImg = '';
    },
  }
}
</script>

<style lang="scss" src="../../../assets/css/mintPage.scss" scoped></style>